import { range } from 'lodash';
import { useState, createContext, useContext, useRef, useEffect } from 'react';
import { useStore } from 'zustand';
import {
  Box,
  Text,
  VStack,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Image,
  Card,
  CardBody,
  useRadioGroup,
  useRadio,
  HStack,
  Spacer,
  UseRadioProps,
  Wrap,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  useBreakpointValue,
  Button,
  Divider,
} from '@chakra-ui/react';

import { MdAdd, MdRemove } from 'react-icons/md';

import {
  Block as BlockType,
  ReadingBlock as ReadingBlockType,
  ImageBlock as ImageBlockType,
  VideoBlock as VideoBlockType,
  TextInputBlock as TextInputBlockType,
  ShortTextInputBlock as ShortTextInputBlockType,
  ScaleBlock as ScaleBlockType,
  SelectBlock as SelectBlockType,
  NumberBlock as NumberBlockType,
  TimeBlock as TimeBlockType,
  DateTimeBlock as DateTimeBlockType,
  GroupBlock as GroupBlockType,
  MultiEntryBlock as MultiEntryBlockType,
} from 'app/data';
import {
  BlockStore,
  createBlockStore,
  BlockStoreState,
} from 'app/stores/block';

import MarkdownRenderer from './MarkdownRenderer';
import Editor from './Editor';

const AvoidBreaksClass: string = 'print-avoid-breaks';

type BlockValueType<B extends BlockType> = B extends TextInputBlockType
  ? string
  : B extends ShortTextInputBlockType
    ? string
    : B extends ScaleBlockType
      ? number | null
      : B extends SelectBlockType
        ? string | null
        : B extends NumberBlockType
          ? number | null
          : B extends TimeBlockType
            ? string | null
            : B extends DateTimeBlockType
              ? string | null
              : never;

type InputBlockChangeHandler<B extends BlockType> = (
  value: BlockValueType<B>
) => void;

interface BlockProps {
  groupId?: string;
  block: BlockType;
  disabled?: boolean;
}

const Block = ({ groupId, block, disabled }: BlockProps) => {
  return (
    <VStack align="start" mb={4} pt={4} pb={4} w="100%">
      <Text fontSize="xl" fontWeight="semibold">
        {block.heading}
      </Text>
      <BlockRenderer groupId={groupId} block={block} disabled={disabled} />
    </VStack>
  );
};

const BlockList = () => {
  const [blocks, disabled] = useBlockContext((state) => [
    state.blocks,
    state.disabled,
  ]);

  return (
    <>
      {blocks.map((block) => (
        <Block key={block.id} block={block} disabled={disabled} />
      ))}
    </>
  );
};

const ReadingBlock = ({ block }: { block: ReadingBlockType }) => (
  <VStack align="start" w="100%">
    <MarkdownRenderer>{block.content}</MarkdownRenderer>
  </VStack>
);

const ImageBlock = ({ block }: { block: ImageBlockType }) => (
  <Image mt={2} src={block.url} />
);

const VideoBlock = ({ block }: { block: VideoBlockType }) => {
  return (
    <Flex position="relative" overflow="hidden" w="100%" aspectRatio={16 / 9}>
      <iframe
        title={block.heading}
        src={block.url}
        width="100%"
        height="100%"
        allowFullScreen
      />
    </Flex>
  );
};

interface TextInputBlockProps {
  block: TextInputBlockType;
  disabled?: boolean;
  value?: string;
  onInputChange?: InputBlockChangeHandler<TextInputBlockType>;
}

const TextInputBlock = ({
  block,
  disabled,
  value,
  onInputChange,
}: TextInputBlockProps) => {
  // XXX figure out how to set editor state when value changes to something it isn't
  return (
    <Box width="100%" className={AvoidBreaksClass}>
      <Text fontSize="sm" mb={2}>
        {block.subheading}
      </Text>
      <Editor
        initialValue={value || ''}
        disabled={disabled}
        onChangeEnd={(value) => onInputChange?.(value)}
      />
    </Box>
  );
};

interface ShortTextInputBlockProps {
  block: ShortTextInputBlockType;
  disabled?: boolean;
  value?: string;
  onInputChange?: InputBlockChangeHandler<ShortTextInputBlockType>;
}

const ShortTextInputBlock = ({
  block,
  disabled,
  value,
  onInputChange,
}: ShortTextInputBlockProps) => {
  return (
    <Box width="100%" className={AvoidBreaksClass}>
      <Text fontSize="sm" mb={2}>
        {block.subheading}
      </Text>
      <Input
        className={AvoidBreaksClass}
        type="text"
        value={value || ''}
        width="100%"
        maxW="xl"
        borderColor="gray.400"
        isDisabled={!!disabled}
        onChange={(e) => onInputChange?.(e.target.value)}
      ></Input>
    </Box>
  );
};

//Create a component that consumes the `useRadio` hook for both scale and select blocks
function RadioCard(props: UseRadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        textAlign="center"
        cursor="pointer"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.400"
        _disabled={{
          bg: 'gray.100',
        }}
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
          _disabled: {
            bg: 'teal.500',
            borderColor: 'teal.500',
          },
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={3}
        py={1.5}
        fontSize="md"
      >
        {props.value}
      </Box>
    </Box>
  );
}

interface ScaleBlockProps {
  block: ScaleBlockType;
  disabled?: boolean;
  value?: number | null;
  onInputChange?: InputBlockChangeHandler<ScaleBlockType>;
}

const ScaleBlock = ({
  block,
  disabled,
  value,
  onInputChange,
}: ScaleBlockProps) => {
  //Use the `useRadioGroup` hook to control a group of custom radios.
  //Generate the values for the scale
  const options = Array.from(
    { length: Math.floor(block.max - block.min) + 1 },
    (_, i) => block.min + i
  ).map(String);

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: value ? value.toString() : '',
    onChange: (value) => {
      const valueAsNumber = Number(value);
      onInputChange?.(Number.isFinite(valueAsNumber) ? valueAsNumber : null);
    },
  });

  const group = getRootProps();

  const scaleLabelsAbove = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack className={AvoidBreaksClass} maxW="30em">
      {scaleLabelsAbove && (
        <VStack w="100%" align="start" pb={2}>
          {block.min_label && (
            <Text fontSize="sm" align="center">
              {`${block.min}: ${block.min_label}`}{' '}
            </Text>
          )}
          {block.mid_label && (
            <Text fontSize="sm" align="center">
              {`${Math.ceil((block.max - block.min) / 2)}: ${block.mid_label}`}{' '}
            </Text>
          )}
          {block.max_label && (
            <Text fontSize="sm" align="center">
              {`${block.max}: ${block.max_label}`}{' '}
            </Text>
          )}
        </VStack>
      )}
      <Wrap justify="center" {...group}>
        {options.map((option) => {
          const radio = getRadioProps({ value: option });
          return (
            <RadioCard
              key={option}
              isDisabled={disabled}
              value={option}
              {...radio}
            />
          );
        })}
      </Wrap>
      {!scaleLabelsAbove && (
        <HStack w="100%" align="stretch">
          <Text maxW="25%" fontSize="sm" align="center">
            {block.min_label}
          </Text>
          <Spacer />
          <Text maxW="25%" fontSize="sm" align="center">
            {block.mid_label}
          </Text>
          <Spacer />
          <Text maxW="25%" fontSize="sm" align="center">
            {block.max_label}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

//Create a component that consumes the `useRadio` hook for both scale and select blocks
interface SelectBlockProps {
  block: SelectBlockType;
  value?: string | null;
  disabled?: boolean;
  onInputChange?: InputBlockChangeHandler<SelectBlockType>;
}

const SelectBlock = ({
  block,
  value,
  disabled,
  onInputChange,
}: SelectBlockProps) => {
  //Use the `useRadioGroup` hook to control a group of custom radios.

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: value || undefined,
    onChange: (value) => {
      onInputChange?.(value);
      setExpandOptions(false);
    },
  });

  const group = getRootProps();
  const [expandOptions, setExpandOptions] = useState(!disabled);

  return (
    <>
      {Array.isArray(block.options) ? (
        <VStack>
          <Wrap {...group} direction="column">
            {block.options.map((option: string) => {
              const radio = getRadioProps({ value: option });
              return (
                <RadioCard
                  key={option}
                  isDisabled={disabled}
                  value={option}
                  {...radio}
                />
              );
            })}
          </Wrap>
        </VStack>
      ) : (
        <Accordion
          allowToggle
          index={expandOptions ? 0 : -1}
          onChange={(expandedIndex) => {
            expandedIndex == 0
              ? setExpandOptions(true)
              : setExpandOptions(false);
          }}
          p={0}
          w="100%"
        >
          <AccordionItem border="none">
            <AccordionButton
              justifyContent="flex-end"
              background={disabled ? 'gray.100 ' : 'transparent'}
              _expanded={{ background: 'transparent' }}
            >
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                gap={6}
                w="100%"
              >
                <Box
                  textAlign="left"
                  px={3}
                  py={1.5}
                  fontSize="md"
                  borderRadius="md"
                  color="white"
                  background={
                    expandOptions || !value
                      ? ''
                      : disabled
                        ? 'teal.500'
                        : 'teal.600'
                  }
                >
                  {expandOptions ? '' : value || ''}
                </Box>
                <Flex gap={2}>
                  <Text fontSize="sm">
                    {expandOptions || disabled ? '' : 'see all options'}
                  </Text>
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
            <AccordionPanel p={0}>
              <VStack>
                {Object.entries(block.options).map(([groupName, groupList]) => (
                  <VStack key={groupName} align="start" w="100%" pb={2}>
                    <Text fontSize="sm">{groupName}</Text>
                    <Wrap {...group}>
                      {groupList.map((option) => {
                        const radio = getRadioProps({ value: option });
                        return (
                          <RadioCard
                            key={option}
                            isDisabled={disabled}
                            value={option}
                            {...radio}
                          />
                        );
                      })}
                    </Wrap>
                  </VStack>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};

interface NumberBlockProps {
  block: NumberBlockType;
  disabled?: boolean;
  value?: number | null;
  onInputChange?: InputBlockChangeHandler<NumberBlockType>;
}

const NumberBlock = ({
  block,
  disabled,
  value,
  onInputChange,
}: NumberBlockProps) => {
  return (
    <NumberInput
      className={AvoidBreaksClass}
      size="md"
      maxW={24}
      value={value !== null ? value : undefined}
      min={block.min}
      max={block.max}
      isDisabled={!!disabled}
      onChange={(_, valueAsNumber) =>
        onInputChange?.(!isNaN(valueAsNumber) ? valueAsNumber : null)
      }
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

interface TimeBlockProps {
  block: TimeBlockType;
  disabled?: boolean;
  value?: string | null;
  onInputChange?: InputBlockChangeHandler<TimeBlockType>;
}

const TimeBlock = ({ disabled, value, onInputChange }: TimeBlockProps) => (
  <Input
    className={AvoidBreaksClass}
    type="time"
    value={value !== null ? value : undefined}
    width="auto"
    isDisabled={!!disabled}
    onChange={(e) =>
      onInputChange?.(e.target.value !== '' ? e.target.value : null)
    }
  ></Input>
);

interface DateTimeBlockProps {
  block: DateTimeBlockType;
  disabled?: boolean;
  value?: string | null;
  onInputChange?: InputBlockChangeHandler<DateTimeBlockType>;
}

const DateTimeBlock = ({
  disabled,
  value,
  onInputChange,
}: DateTimeBlockProps) => (
  <Input
    className={AvoidBreaksClass}
    type="datetime-local"
    value={value !== null ? value : undefined}
    width="auto"
    isDisabled={!!disabled}
    onChange={(e) =>
      onInputChange?.(e.target.value !== '' ? e.target.value : null)
    }
  ></Input>
);

interface GroupBlockProps {
  block: GroupBlockType;
  disabled?: boolean;
}

const GroupBlock = ({ block, disabled }: GroupBlockProps) => {
  const { blocks } = block;

  return (
    <Card
      width="100%"
      backgroundColor="transparent"
      borderLeft="1px solid var(--chakra-colors-gray-400)"
      variant="solid"
      borderRadius={0}
    >
      <CardBody pl={{ base: 2, md: 4 }} py={0}>
        {blocks.map((childBlock) => (
          <Block
            key={childBlock.id}
            groupId={block.id}
            block={childBlock}
            disabled={disabled}
          />
        ))}
      </CardBody>
    </Card>
  );
};

interface MultiEntryBlockProps {
  block: MultiEntryBlockType;
  disabled?: boolean;
}

const MultiEntryBlock = ({ block, disabled }: MultiEntryBlockProps) => {
  const { blocks } = block;

  const [addEntry, removeEntry, entryCount, multiEntryKeys, values] =
    useBlockContext((state) => [
      state.addMultiEntry,
      state.removeMultiEntry,
      state.getMultiEntryCount(block.id),
      state.getMultiEntryKeys(block.id),
      state.values,
    ]);

  const entries = range(entryCount).map(() => blocks[0]);

  const isLastEntryFilled = !!multiEntryKeys.find(
    (key) => key.startsWith(`${block.id}.${entryCount - 1}`) && !!values[key]
  );

  // Disable add entry if the last blocks in the group hasn't been filled out,
  // so that we don't end up with empty group blocks in the middle of the group array
  const disableAddEntry = !isLastEntryFilled;
  const disableRemoveEntry = entryCount <= 1;

  return (
    <Card
      width="100%"
      backgroundColor="transparent"
      borderLeft="1px solid var(--chakra-colors-gray-400)"
      variant="solid"
      borderRadius={0}
    >
      <CardBody pl={{ base: 2, md: 4 }} py={0}>
        {entries.map((entry, idx) => {
          return (
            <div key={idx}>
              {idx > 0 && <Divider width="100%" my={2} />}
              {entry.map((entryBlock) => (
                <Block
                  key={`${block.id}.${idx}.${entryBlock.id}`}
                  groupId={`${block.id}.${idx}`}
                  block={entryBlock}
                  disabled={disabled}
                />
              ))}
            </div>
          );
        })}
        {!disabled && (
          <Flex direction="column" align="start" w="100%" gap={2}>
            <Button
              isDisabled={disableAddEntry}
              variant="link"
              colorScheme="blue"
              fontSize="sm"
              leftIcon={<MdAdd />}
              onClick={() => addEntry(block.id)}
            >
              Add another entry
            </Button>
            <Button
              isDisabled={disableRemoveEntry}
              variant="link"
              colorScheme="gray"
              fontSize="sm"
              leftIcon={<MdRemove />}
              onClick={() => removeEntry(block.id, entryCount - 1)}
            >
              Remove last entry
            </Button>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

interface BlockRendererProps {
  groupId?: string;
  block: BlockType;
  disabled?: boolean;
}

const BlockRenderer = ({ groupId, block, ...props }: BlockRendererProps) => {
  const id = groupId ? `${groupId}.${block.id}` : block.id;
  const [value, updateValue] = useBlockContext((state) => [
    state.values[id],
    state.updateValue,
  ]);

  switch (block.type) {
    case 'reading':
      return <ReadingBlock block={block} {...props} />;
    case 'image':
      return <ImageBlock block={block} {...props} />;
    case 'video':
      return <VideoBlock block={block} {...props} />;
    case 'text_input':
      return (
        <TextInputBlock
          block={block}
          value={value as string | undefined}
          onInputChange={(value: string) => updateValue(id, value)}
          {...props}
        />
      );
    case 'short_text_input':
      return (
        <ShortTextInputBlock
          block={block}
          value={value as string | undefined}
          onInputChange={(value: string) => updateValue(id, value)}
          {...props}
        />
      );
    case 'scale':
      return (
        <ScaleBlock
          block={block}
          value={value as number | undefined | null}
          onInputChange={(value: number | null) => updateValue(id, value)}
          {...props}
        />
      );
    case 'select':
      return (
        <SelectBlock
          block={block}
          value={value as string | undefined | null}
          onInputChange={(value: string | null) => updateValue(id, value)}
          {...props}
        />
      );
    case 'number':
      return (
        <NumberBlock
          block={block}
          value={value as number | undefined | null}
          onInputChange={(value: number | null) => updateValue(id, value)}
          {...props}
        />
      );
    case 'time':
      return (
        <TimeBlock
          block={block}
          value={value as string | undefined | null}
          onInputChange={(value: string | null) => updateValue(id, value)}
          {...props}
        />
      );
    case 'datetime':
      return (
        <DateTimeBlock
          block={block}
          value={value as string | undefined | null}
          onInputChange={(value: string | null) => updateValue(id, value)}
          {...props}
        />
      );
    case 'group':
      return <GroupBlock block={block} {...props} />;
    case 'multi_entry':
      return <MultiEntryBlock block={block} {...props} />;
    default: {
      const _exhaustiveCheck: never = block;
      return _exhaustiveCheck;
    }
  }
};

const BlockContext = createContext<BlockStore | undefined>(undefined);

interface BlockContextProviderProps {
  blocks?: BlockType[];
  values?: Record<string, string | number | null>;
  disabled?: boolean;
  children: React.ReactNode;
}

const BlockContextProvider = ({
  blocks,
  values,
  disabled,
  children,
}: BlockContextProviderProps) => {
  const storeRef = useRef<BlockStore>();
  if (!storeRef.current) {
    storeRef.current = createBlockStore(blocks, values, disabled);
  }

  useEffect(() => {
    if (
      storeRef.current &&
      disabled !== undefined &&
      storeRef.current.getState().disabled !== disabled
    ) {
      storeRef.current.getState().setDisabled(disabled);
    }
  }, [storeRef.current, disabled]);

  return (
    <BlockContext.Provider value={storeRef.current}>
      {children}
    </BlockContext.Provider>
  );
};

const useBlockContext = <T extends object>(
  selector: (state: BlockStoreState) => T
): T => {
  const store = useContext(BlockContext);
  if (!store) {
    throw new Error(
      'useBlockContext must be used within a BlockContextProvider'
    );
  }

  return useStore(store, selector);
};

interface BlockComposerProps {
  blocks?: BlockType[];
  values?: Record<string, string | number | null>;
  disabled?: boolean;
  children?: React.ReactNode;
}

const BlockComposer = ({
  blocks,
  values,
  disabled,
  children,
}: BlockComposerProps) => (
  <BlockContextProvider blocks={blocks} values={values} disabled={disabled}>
    {children}
  </BlockContextProvider>
);

export default Block;
export {
  BlockComposer,
  BlockContextProvider,
  BlockList,
  useBlockContext,
  TextInputBlock,
  ShortTextInputBlock,
  ReadingBlock,
};
