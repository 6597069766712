import {
  Container,
  Image,
  Flex,
  Text,
  VStack,
  Heading,
  Spacer,
  ButtonGroup,
  Button,
  Wrap,
  CardBody,
  Card,
  ListItem,
  List,
  ListIcon,
} from '@chakra-ui/react';
import { BasePage, Section } from 'app/pages/Page';
import {
  MdArrowRightAlt,
  MdAutoGraph,
  MdMobileFriendly,
  MdOutlineLock,
  MdOutlineSave,
  MdOutlineSend,
  MdSearch,
} from 'react-icons/md';
import Link from 'app/components/Link';
import { onGetStartedClick } from 'app/analytics';

const Home = () => {
  return (
    <BasePage title="Digital Therapy Workbook">
      <Section>
        <Flex
          justify="stretch"
          align={{ base: 'center', xl: 'start' }}
          direction={{ base: 'column', xl: 'row' }}
          pt={8}
          pl={4}
          pr={4}
          gap={6}
        >
          <Flex direction="column" maxW={{ base: '48em', xl: '40em' }}>
            <Heading size="2xl" fontWeight="semibold" lineHeight={1.25}>
              A digital workbook designed to enhance therapy
            </Heading>
            <Text pt={8} fontSize="xl">
              Outside Therapy is the first{' '}
              <b>customizable, evidence-based digital therapy workbook</b> that
              helps therapists and clients make more progress together.
            </Text>
            <Flex pt={8} gap={6} flexDirection="row" justify="center">
              <Link to="/templates">
                <Button
                  rightIcon={<MdArrowRightAlt size="24" />}
                  colorScheme="blue"
                  onClick={onGetStartedClick}
                >
                  Get started for free
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Spacer />
          <Image
            w={{ base: '100%', lg: '75%' }}
            maxW={600}
            src="home_screenshots_1.png"
          ></Image>
        </Flex>
      </Section>

      <Section>
        <Wrap
          w="100%"
          justify="center"
          align="top"
          spacing={8}
          pl={0}
          pr={0}
          m={0}
        >
          <Card>
            <CardBody>
              <Container
                m={0}
                pt={4}
                pb={2}
                w={{ base: '100%', xl: '32em' }}
                maxW="40em"
              >
                <Heading size="lg" fontWeight="semibold" pb={6}>
                  For Therapists
                </Heading>
                <List spacing={3}>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdSearch} fontSize="2xl" mr={4} />
                      Find evidence-based therapy activities and worksheets
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineSend} fontSize="2xl" mr={4} />
                      Send a link to your clients so they can complete
                      activities digitally
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineSave} fontSize="2xl" mr={4} />
                      Clients can download activities as PDFs or save securely
                      on the website
                    </Flex>
                  </ListItem>
                </List>
                <ButtonGroup pt={10} spacing={3}>
                  <Link to="/templates">
                    <Button>Browse therapy activities</Button>
                  </Link>
                </ButtonGroup>
              </Container>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Container
                pt={4}
                pb={2}
                w={{ base: '100%', xl: '32em' }}
                maxW="40em"
              >
                <Heading size="lg" fontWeight="semibold" pb={6}>
                  For Clients
                </Heading>
                <List spacing={3}>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdMobileFriendly} fontSize="2xl" mr={4} />
                      Access your therapy workbook on the go instead of carrying
                      paper books or PDFs
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdAutoGraph} fontSize="2xl" mr={4} />
                      Get more out of your therapy sessions by making practice
                      and reflection easy
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineLock} fontSize="2xl" mr={4} />
                      Secure, free, and no account required
                    </Flex>
                  </ListItem>
                </List>
                <ButtonGroup pt={10} spacing={3}>
                  <Link to="/templates">
                    <Button>Get started for free</Button>
                  </Link>
                </ButtonGroup>
              </Container>
            </CardBody>
          </Card>
        </Wrap>
      </Section>

      <Section>
        <Flex justify="center" align="center">
          <Container m={0} pt={8} pb={2} maxW={{ lg: '48em' }}>
            <Heading size="lg" fontWeight="semibold" lineHeight={1.25} pb={4}>
              How can we help your practice?
            </Heading>
            <VStack align="left">
              <Text fontSize={{ base: 'lg', xl: 'xl' }}>
                Reach us at{' '}
                <Link.WithoutRouter
                  color="teal.600"
                  href="mailto:hello@getoutsidetherapy.com"
                  fontWeight="semibold"
                >
                  hello@getoutsidetherapy.com
                </Link.WithoutRouter>{' '}
                or read more about{' '}
                <Link color="teal.600" to="/about" fontWeight="semibold">
                  our vision
                </Link>
              </Text>
              <Text fontSize={{ base: 'lg', xl: 'xl' }}>
                We are committed to making our product accessible for therapists
                and clients.
              </Text>
            </VStack>
          </Container>
        </Flex>
      </Section>
    </BasePage>
  );
};

export default Home;
