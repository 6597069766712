import { useParams, useNavigate } from 'react-router-dom';
import {
  VStack,
  Flex,
  Text,
  ButtonGroup,
  Divider,
  Center,
  Switch,
  FormLabel,
  Spinner,
  Spacer,
  HStack,
  Card,
  CardBody,
  useToast,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import {
  BlockComposer,
  BlockList,
  useBlockContext,
} from 'app/components/Block';
import {
  useTemplateGetBySlug,
  useActivityCreate,
  Template,
  useAccountProfileGet,
} from 'app/data';

import { TemplateCardTag } from 'app/components/TemplateCardTag';
import useAuthStore from 'app/stores/auth';

import { useRef } from 'react';
import {
  DownloadButton,
  DownloadButtonProps,
} from 'app/components/DownloadButton';

interface SaveButtonProps {
  downloadButtonProps: DownloadButtonProps;
  authenticated: boolean;
  isLoading: boolean;
  isProvider: boolean | undefined;
  onSave: () => void;
}

const SaveButtons = ({ downloadButtonProps }: SaveButtonProps) => {
  return (
    <ButtonGroup>
      <DownloadButton {...downloadButtonProps} />
    </ButtonGroup>
  );
};

interface ActivityBlockPluginProps {
  template: Template;
}

const ActivityBlockPlugin = ({ template }: ActivityBlockPluginProps) => {
  const authenticated = useAuthStore((state) => !!state.token);
  const accountProfile = useAccountProfileGet();

  const [values] = useBlockContext((state) => [state.values]);

  const downloadRef = useRef<HTMLDivElement>(null);

  const toast = useToast();
  const navigate = useNavigate();
  const create = useActivityCreate({
    mutation: {
      onSuccess: (data) => {
        toast({
          title: 'Activity Saved',
          description:
            'Thank you for investing in your mental health. See past activities in "My Workbook".',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        navigate(`/workbook/activity/${data.id}`);
      },
    },
  });

  const onSave = () => {
    create.mutate({
      data: { template_id: template.id, content: { values } },
    });
  };

  return (
    <>
      <VStack align="start" spacing={4} w="100%" ref={downloadRef}>
        <Flex w="100%" gap="2">
          <Text
            fontSize="4xl"
            fontWeight="bold"
            fontFamily="DM Serif Text, serif"
          >
            {template.title}
          </Text>
        </Flex>
        <Flex w="100%" gap="1" align="center">
          <VStack justify="left" spacing={3}>
            <HStack spacing={2} justify="left" w="100%">
              {template.tags.map((tag: string, idx: number) => (
                <TemplateCardTag key={idx} name={tag} type="tag" />
              ))}
              {<TemplateCardTag name={template.type} type="type" />}
            </HStack>
          </VStack>
          <Spacer />
          <SaveButtons
            downloadButtonProps={{
              downloadRef,
              downloadItemSlug: template.slug,
            }}
            authenticated={authenticated}
            isLoading={create.isPending || accountProfile.isPending}
            isProvider={accountProfile.data?.is_provider}
            onSave={onSave}
          />
        </Flex>
        <Divider width="100%" mt={2} mb={6} />
        <Card variant="filled" w="100%">
          <CardBody>
            <Text fontSize="md">{template.description}</Text>
          </CardBody>
        </Card>
        <Flex direction="column" align="start" justify="center" w="100%">
          <BlockList />
        </Flex>
      </VStack>

      <Divider width="90%" m={4} />

      <Center display="none">
        <FormLabel htmlFor="visibleToTherapist">
          Make this visible to your therapist?
        </FormLabel>
        <Switch id="visibleToTherapist" />
      </Center>
      <Flex w="100%" p={4} justify="right">
        <SaveButtons
          downloadButtonProps={{ downloadRef, downloadItemSlug: template.slug }}
          authenticated={authenticated}
          isLoading={create.isPending || accountProfile.isPending}
          isProvider={accountProfile.data?.is_provider}
          onSave={onSave}
        />
      </Flex>
    </>
  );
};

const ActivityCreate = () => {
  const params = useParams<{ templateSlug: string }>();
  const {
    isPending,
    data: template,
    error,
  } = useTemplateGetBySlug(params.templateSlug!);

  return (
    <Page title={isPending || error ? 'Start activity' : template.title}>
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading the activity.</Text>
        </Flex>
      ) : (
        <BlockComposer blocks={template!.blocks}>
          <ActivityBlockPlugin template={template} />
        </BlockComposer>
      )}
    </Page>
  );
};

export default ActivityCreate;
