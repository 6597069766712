import { Button } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import * as Analytics from 'app/analytics';

export interface DownloadButtonProps {
  downloadRef: MutableRefObject<HTMLDivElement | null>;
  downloadItemSlug: string;
}

export const DownloadButton = ({
  downloadRef,
  downloadItemSlug,
}: DownloadButtonProps) => {
  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
    bodyClass: 'print-content',
    documentTitle: `${downloadItemSlug}-${new Date().toISOString()}`,
  });

  return (
    <Button
      colorScheme="blue"
      size="md"
      aria-label={'Download'}
      onClick={() => {
        handlePrint();
        Analytics.onDownloadActivityClick(downloadItemSlug);
      }}
      leftIcon={<MdOutlineFileDownload />}
    >
      Download as PDF
    </Button>
  );
};
