import posthog from 'posthog-js';

export const initialize = () => {
  posthog.init('', {
    api_host: 'https://us.i.posthog.com',
    persistence: 'sessionStorage',
    autocapture: {
      dom_event_allowlist: ['click'],
      element_allowlist: ['button', 'a'],
    },
  });
};

export const identify = (id: string) => {
  posthog.identify(id);
};

export const reset = () => {
  posthog.reset();
};

const trackEvent = (
  eventId: string,
  payload?: Record<string, number | string | boolean>
) => {
  posthog.capture(eventId, payload);
};

export const onDownloadActivityClick = (downloadItemSlug: string) =>
  trackEvent(`download_activity click ${downloadItemSlug}`);

export const onCopyLinkClick = () => trackEvent(`copy_link click`);

export const onWaitlistSubmit = () => trackEvent(`waitlist submit`);

export const onGetStartedClick = () => trackEvent(`get_started click`);
