import { AxiosRequestConfig, AxiosError } from 'axios';
import useAuthStore from 'app/stores/auth';

import MOCK_API from 'app/data/mock_api';

export const useCustomInstance = <T>(): ((
  config: AxiosRequestConfig
) => Promise<T>) => {
  return (config: AxiosRequestConfig) => {
    useAuthStore.getState().cleanupAuthState();

    try {
      const mocked = MOCK_API.get(config.url!);
      return Promise.resolve(mocked as T);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export type ErrorType<Error> = AxiosError<Error>;
