import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Pages from 'app/pages';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Pages.Root />,
    errorElement: <Pages.Error />,
    children: [
      { path: '/', element: <Pages.Home /> },
      { path: '/about', element: <Pages.About /> },
      {
        path: '/templates',
        element: <Pages.TemplateListPage />,
        children: [{ path: ':slug', element: <Pages.TemplatePreview /> }],
      },
      {
        path: '/guide',
        element: <Pages.Guide />,
      },
      {
        path: '/workbook/new/:templateSlug',
        element: <Pages.ActivityCreate />,
      },
      { path: '/sandbox', element: <Pages.Sandbox /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
